import fetch from '~/utils/fetch';
import {
  REGISTER_URL,
  LOGIN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
} from '~/consts/urls';

export const signUpRequest = (data) =>
  fetch(REGISTER_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const loginRequest = (data) =>
  fetch(LOGIN_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const forgotPasswordRequest = (data) =>
  fetch(FORGOT_PASSWORD_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const resetPasswordRequest = (data) =>
  fetch(RESET_PASSWORD_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
