export const PermissionType = Object.freeze({
  Welcome: 'Welcome',
  Library: 'Library',
  JournalEntry: 'JournalEntry',
  FinanceTracker: 'FinanceTracker',
  HealthTracker: 'HealthTracker',
  MedicationTracker: 'MedicationTracker',
  CareNeedsTool: 'CareNeedsTool',
  Profile: 'Profile',
  Calendar: 'Calendar',
});

export const PermissionAllow = Object.freeze({
  Hide: 'Hide',
  Read: 'Read',
  Write: 'Write',
});

export const keysOfPermissionType = Object.keys(PermissionType);

export const sharingKeysOfPermissionType = Object.keys(PermissionType).filter(
  (type) => type !== PermissionType.Welcome
);

export const modesLabels = {
  [PermissionAllow.Write]: 'Edit',
  [PermissionAllow.Read]: 'View only',
  [PermissionAllow.Hide]: 'Hide',
};
