import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();

  return (
    <footer className="footer">
      <div className="footer__inner container">
        <a
          href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Home.aspx"
          target="_blank"
          className="logo"
        >
          <img src="/img/footer/logo-white.png" alt="" />
        </a>
        <div className="footer__info">
          <ul className="footer__list">
            <li>
              <a href="mailto:carehub@virtualhospice.ca">Contact us</a>
            </li>
            <li>
              {location.pathname === '/about-us' ? (
                <a href="/about-us#privacy">Privacy Notice</a>
              ) : (
                <Link
                  to={{
                    pathname: '/about-us',
                    hash: 'privacy',
                    state: { scrollToPrivacy: true },
                  }}
                >
                  Privacy Notice
                </Link>
              )}
            </li>
            <li>
              <a
                href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Privacy+Policy.aspx"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
          <span>
            Copyright 2022 Canadian Virtual Hospice. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
