import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { AccountType } from '~/consts';

const ABOUT_ME = 'ABOUT_ME';
const CAREGIVER_WELCOME = 'CAREGIVER_WELCOME';
const CAREGIVING_NEEDS = 'CAREGIVING_NEEDS';
const CARE_NEED_TOOL = 'CARE_NEED_TOOL';
const DEFAULT = 'DEFAULT';
const PROFILE = 'PROFILE';
const REMINDERS = 'REMINDERS';
const SHARING = 'SHARING';

const POPUP_FORMS = {
  ABOUT_ME,
  CAREGIVER_WELCOME,
  CAREGIVING_NEEDS,
  CARE_NEED_TOOL,
  DEFAULT,
  PROFILE,
  REMINDERS,
  SHARING,
};

const getFormPopupContent = (accountType, formName, onClose, screenName) => {
  const renderLink = (title, path) => (
    <Link to={path} onClick={onClose}>
      {title}
    </Link>
  );

  const links = {
    aboutMe: renderLink('About Me', '/profile/about-me'),
    dashboard: renderLink('Dashboard', '/welcome'),
    healthReports: renderLink('Health Reports', '/reports'),
    library: renderLink('Library', '/library'),
    reminder: renderLink('Reminder', '/profile/reminders'),
    reminders: renderLink('Reminders', '/profile/reminders'),
    sharing: renderLink('Sharing', '/profile/sharing'),
    whoICare: renderLink('Who I Care For', '/profile/patients'),
  };

  const caregiverContent = {
    [CAREGIVING_NEEDS]: {
      positive: (
        <Fragment>
          Thanks for sharing your caregiving needs. Now you’re ready to start
          using CareHub! What would you like to do next? Based on your answers,
          helpful information has been added to your {links.library}. You can
          add someone you’re caring for in {links.whoICare}, set some{' '}
          {links.reminders}, or go to the {links.dashboard} to start exploring
          on your own.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for sharing your caregiving needs. Now you’re ready to start
          using CareHub! What would you like to do next? Based on your answers,
          helpful information has been added to your {links.library}. You can
          add someone you’re caring for in {links.whoICare}, set some{' '}
          {links.reminders}, or go to the {links.dashboard} to start exploring
          on your own.
        </Fragment>
      ),
    },
    [CAREGIVER_WELCOME]: {
      positive: (
        <Fragment>
          Thanks for taking the time to answer these questions. Revisit this
          tool when things change. Helpful information has been added to the{' '}
          {links.library}.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for taking the time to answer these questions. Revisit this
          tool when things change.
        </Fragment>
      ),
    },
    [ABOUT_ME]: {
      positive: (
        <Fragment>
          Thanks for telling us more. Based on your answers, helpful information
          has been added to the {links.library}. Don't forget to update this
          profile when things change (You can even set a {links.reminder}).
          Next, we are going to ask about your Caregiving Needs.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for telling us more. Based on your answers, helpful information
          has been added to the {links.library}. Don't forget to update this
          profile when things change (You can even set a {links.reminder}).
          Next, we are going to ask about your Caregiving Needs.
        </Fragment>
      ),
    },
    [PROFILE]: {
      positive: (
        <Fragment>
          Thank you for personalizing your CareHub! You can come back any time
          to make changes. Next, please fill in your {links.aboutMe} section so
          we can personalize the information we send you.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thank you for personalizing your CareHub! You can come back any time
          to make changes. Next, please fill in your {links.aboutMe} section so
          we can personalize the information we send you.
        </Fragment>
      ),
    },
    [REMINDERS]: {
      positive: (
        <Fragment>
          Thank for updating your Reminders. Based on your selections, we'll let
          you know when it's time to revisit a section of CareHub.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thank for updating your Reminders. Based on your selections, we'll let
          you know when it's time to revisit a section of CareHub.
        </Fragment>
      ),
    },
    [DEFAULT]: {
      positive: (
        <Fragment>
          Thanks for taking the time to answer these questions. Be sure to come
          back and update the form when things change.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for taking the time to answer these questions. Be sure to come
          back and update the form when things change.
        </Fragment>
      ),
    },
  };

  const patientContent = {
    [CARE_NEED_TOOL]: {
      positive: (
        <Fragment>
          Helpful informaton has added to the {links.library}. Revisit this tool
          on a regular basis or when things change. You can also view a report
          of this information in {links.healthReports}.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Revisit this tool on a regular basis or when things change. You can
          also view a report of this information in {links.healthReports}.
        </Fragment>
      ),
    },
    [ABOUT_ME]: {
      positive: (
        <Fragment>
          Thanks for telling us more. Don't forget to update this profile when
          things change (You can even set a {links.reminder}).
          <br />
          <br />
          Now you’re ready to start using CareHub! What would you like to do
          next? Based on your answers, helpful information has been added to
          your {links.library}. You can share your CareHub with others in{' '}
          {links.sharing}, or go to the {links.dashboard} to start exploring on
          your own.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for telling us more. Don't forget to update this profile when
          things change (You can even set a {links.reminder}).
          <br />
          <br />
          Now you’re ready to start using CareHub! What would you like to do
          next? Based on your answers, helpful information has been added to
          your {links.library}. You can share your CareHub with others in{' '}
          {links.sharing}, or go to the {links.dashboard} to start exploring on
          your own.
        </Fragment>
      ),
    },
    [PROFILE]: {
      positive: (
        <Fragment>
          Thank you for personalizing your CareHub! You can come back any time
          to make changes. Next, please fill in your {links.aboutMe} section so
          we can personalize the information we send you.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thank you for personalizing your CareHub! You can come back any time
          to make changes. Next, please fill in your {links.aboutMe} section so
          we can personalize the information we send you.
        </Fragment>
      ),
    },
    [REMINDERS]: {
      positive: (
        <Fragment>
          Thank for updating your Reminders. Based on your selections, we'll let
          you know when it's time to revisit a section of CareHub.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thank for updating your Reminders. Based on your selections, we'll let
          you know when it's time to revisit a section of CareHub.
        </Fragment>
      ),
    },
    [SHARING]: {
      positive: (
        <Fragment>
          Thanks for updating your Sharing details. You can come back any time
          to add new caregivers or change their access to features.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for updating your Sharing details. You can come back any time
          to add new caregivers or change their access to features.
        </Fragment>
      ),
    },
    [DEFAULT]: {
      positive: (
        <Fragment>
          Thanks for taking the time to answer these questions. Be sure to come
          back and update the form when things change.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for taking the time to answer these questions. Be sure to come
          back and update the form when things change.
        </Fragment>
      ),
    },
  };

  const impersonationContent = {
    [CARE_NEED_TOOL]: {
      positive: (
        <Fragment>
          Helpful informaton has added to the {links.library}. Revisit this tool
          on a regular basis or when things change. You can also view a report
          of this information in {links.healthReports}.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Revisit this tool on a regular basis or when things change. You can
          also view a report of this information in {links.healthReports}.
        </Fragment>
      ),
    },
    [PROFILE]: {
      positive: (
        <Fragment>
          Thank you for personalizing {screenName}'s CareHub! You can come back
          any time to make changes. Next, please fill in the{' '}
          <Link to="/profile/about-me" onClick={onClose}>
            About {screenName}
          </Link>{' '}
          section so we can personalize the information we send.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thank you for personalizing {screenName}'s CareHub! You can come back
          any time to make changes. Next, please fill in the{' '}
          <Link to="/profile/about-me" onClick={onClose}>
            About {screenName}
          </Link>{' '}
          section so we can personalize the information we send.
        </Fragment>
      ),
    },
    [ABOUT_ME]: {
      positive: (
        <Fragment>
          Thanks for telling us more. Don't forget to update this profile when
          things change.
          <br />
          <br />
          What would you like to do next? Based on your answers, helpful
          information has been added to the {links.library}. You can share{' '}
          {screenName}'s CareHub with others in {links.sharing}, or go to the{' '}
          {links.dashboard} to start exploring on your own.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for telling us more. Don't forget to update this profile when
          things change.
          <br />
          <br />
          What would you like to do next? Based on your answers, helpful
          information has been added to the {links.library}. You can share{' '}
          {screenName}'s CareHub with others in {links.sharing}, or go to the{' '}
          {links.dashboard} to start exploring on your own.
        </Fragment>
      ),
    },
    [SHARING]: {
      positive: (
        <Fragment>
          Thanks for updating your Sharing details. You can come back any time
          to add new caregivers or change their access to features.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for updating your Sharing details. You can come back any time
          to add new caregivers or change their access to features.
        </Fragment>
      ),
    },
    [REMINDERS]: {
      positive: (
        <Fragment>
          Thank for updating your Reminders. Based on your selections, we'll let
          you know when it's time to revisit a section of CareHub.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thank for updating your Reminders. Based on your selections, we'll let
          you know when it's time to revisit a section of CareHub.
        </Fragment>
      ),
    },
    [DEFAULT]: {
      positive: (
        <Fragment>
          Thanks for taking the time to answer these questions. Be sure to come
          back and update the form when things change.
        </Fragment>
      ),
      negative: (
        <Fragment>
          Thanks for taking the time to answer these questions. Be sure to come
          back and update the form when things change.
        </Fragment>
      ),
    },
  };

  let result = {};

  if (accountType === AccountType.CareGiver) {
    result = caregiverContent[formName];
  }

  if (accountType === AccountType.Patient) {
    result = patientContent[formName];
  }

  if (screenName) {
    result = impersonationContent[formName];
  }

  return result || {};
};

export { getFormPopupContent, POPUP_FORMS };
