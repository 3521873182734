import jwtDecode from 'jwt-decode';

const ImpersonateRole =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const IsImpersonated = () => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    return false;
  }

  let decoded;

  try {
    decoded = jwtDecode(authToken);
  } catch {
    window.location.href = '/login';
    return;
  }

  let roles = decoded[ImpersonateRole];

  if (typeof roles === 'string') {
    roles = [roles];
  }

  if (roles instanceof Array && roles.includes('CanDeimpersonate')) {
    return true;
  }
  return false;
};

export const GetImpersonatedUser = () => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    const accountType = null;
    const roles = [];
    const isImpersonated = false;
    return { accountType, roles, isImpersonated };
  }
  let decoded;

  try {
    decoded = jwtDecode(authToken);
  } catch {
    window.location.href = '/login';
    return;
  }

  const accountType = decoded['account_type'];
  let roles = decoded[ImpersonateRole];

  if (typeof roles === 'string') {
    roles = [roles];
  }

  const isImpersonated =
    (roles instanceof Array && roles.includes('CanDeimpersonate')) || false;

  return { accountType, roles, isImpersonated };
};
