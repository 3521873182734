import React from 'react';
import Modal from 'react-modal';

import useAppState, { useAppDispatch } from '~/appState';
import { showAlert } from '~/actions/global';

import { ReactComponent as Bell } from '~/assets/svg/Bell.svg';

import './styles.scss';

function AlertModal() {
  const {
    global: { isShowedAlert, alertMessage },
  } = useAppState();
  const dispatch = useAppDispatch();

  function handleCloseClick() {
    dispatch(showAlert(!isShowedAlert));
  }

  return (
    <Modal
      className="alert-modal"
      isOpen={isShowedAlert}
      onRequestClose={handleCloseClick}
      shouldCloseOnOverlayClick={true}
    >
      <button className="close-modal" onClick={handleCloseClick}>
        <div className="font-semibold">close</div>
        <span>&times;</span>
      </button>

      <div className="alert-modal_row">
        <span>
          <Bell />
        </span>
        <div>
          <div
            className="alert-modal_description"
            dangerouslySetInnerHTML={{ __html: alertMessage }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AlertModal;
