import React, { useEffect, useMemo, useState } from 'react';
import useAppState from '~/appState';
import Loader from '~/components/Loader';
import { useSuggestions } from '~/hooks';
import { POPUP_FORMS } from '~/consts/suggestionsAndTips/formPopupsContent';

function SuggestionsLoading() {
  const [isLoading, setLoading] = useState(false);

  const {
    suggestions: { displayedSuggestionIds, hiddenSuggestionIds },
  } = useAppState();

  const handleCompleted = () => {
    setLoading(false);
  };

  const { suggestions, addedSuggestions, handleAddSuggestions } =
    useSuggestions(POPUP_FORMS.DEFAULT, handleCompleted);

  const shownSuggestions = useMemo(() => {
    return displayedSuggestionIds
      .filter(
        (sId) => !hiddenSuggestionIds.includes(sId) && !addedSuggestions[sId]
      )
      .map((sId) => suggestions[sId]);
  }, [
    displayedSuggestionIds,
    hiddenSuggestionIds,
    addedSuggestions,
    suggestions,
  ]);

  const isOpen = shownSuggestions && shownSuggestions.length > 0;

  useEffect(() => {
    if (isOpen) {
      let ids = [];
      for (let i = 0; i < shownSuggestions.length; i++) {
        if (shownSuggestions[i]) {
          ids.push(shownSuggestions[i].id);
        }
      }

      if (ids.length > 0) {
        setLoading(true);
        handleAddSuggestions(ids);
      }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return isOpen && isLoading && <Loader />;
}

export default SuggestionsLoading;
