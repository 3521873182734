import { useEffect } from 'react';

const useOutsideClick = (ref, callback = () => {}) => {
  const navigationButton = document.getElementById('navbar-menu-button');

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        navigationButton &&
        !navigationButton.contains(event.target)
      ) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref, navigationButton]);
};

export default useOutsideClick;
