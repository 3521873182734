import React from 'react';
import { AccountType } from '~/consts';

const MY_PROFILE = { path: '/profile', name: 'My Profile' };
const SHARING = { path: '/profile/sharing', name: 'Sharing' };
const CAREGIVING_NEEDS = {
  path: '/profile/caregiving-needs',
  name: 'Caregiving Needs',
};
const REMINDERS = { path: '/profile/reminders', name: 'Reminders' };
const WHO_I_CARE_FOR = { path: '/profile/patients', name: 'Who I Care For' };

// in impersonation it is "About ${screenName}"
const ABOUT_ME = { path: '/profile/about-me', name: 'About me' };

const ALL_TABS = {
  MY_PROFILE,
  SHARING,
  CAREGIVING_NEEDS,
  REMINDERS,
  WHO_I_CARE_FOR,
  ABOUT_ME,
};
const CAREGIVER_PROFILE_TABS = [
  MY_PROFILE,
  ABOUT_ME,
  CAREGIVING_NEEDS,
  WHO_I_CARE_FOR,
  REMINDERS,
];
const PATIENT_PROFILE_TABS = [MY_PROFILE, ABOUT_ME, SHARING, REMINDERS];
const IMPERSONATION_PROFILE_TABS = [MY_PROFILE, ABOUT_ME, SHARING];

const getAvailableTabs = (accountType, isImpersonated) => {
  let availableTabs = [];

  if (accountType === AccountType.Patient) {
    availableTabs = PATIENT_PROFILE_TABS;
  }
  if (accountType === AccountType.CareGiver) {
    availableTabs = CAREGIVER_PROFILE_TABS;
  }
  if (isImpersonated) {
    availableTabs = IMPERSONATION_PROFILE_TABS;
  }

  return availableTabs;
};

const mapTabContent = ({
  activeTabPath,
  myProfile,
  sharing,
  caregivingNeeds,
  reminders,
  whoICareFor,
  aboutMe,
}) =>
  ({
    [MY_PROFILE.path]: myProfile,
    [SHARING.path]: sharing,
    [CAREGIVING_NEEDS.path]: caregivingNeeds,
    [REMINDERS.path]: reminders,
    [WHO_I_CARE_FOR.path]: whoICareFor,
    [ABOUT_ME.path]: aboutMe,
  }[activeTabPath] || <></>);

export { ALL_TABS, getAvailableTabs, mapTabContent };
