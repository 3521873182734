import fetch from '~/utils/fetch';
import { ALL_EVENTS_URL, EVENTS_URL } from '~/consts/urls';

export const createEventRequest = (data) =>
  fetch(EVENTS_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const updateEventRequest = ({ eventId, ...body }) =>
  fetch(`${EVENTS_URL}/${eventId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

export const deleteEventRequest = (eventId) =>
  fetch(`${EVENTS_URL}/${eventId}`, {
    method: 'DELETE',
  });

export const getEventsRequest = (from = '', to = '') =>
  fetch(`${EVENTS_URL}?dtFrom=${from}&dtTo=${to}`, {
    method: 'GET',
  });

export const getAllEventsRequest = (from = '', to = '') =>
  fetch(`${ALL_EVENTS_URL}?dtFrom=${from}&dtTo=${to}`, {
    method: 'GET',
  });
