import {
  AccountType,
  PermissionType,
  PermissionAllow,
  REPORT_TYPES,
} from '~/consts';

const { SYMPTOMS, CONCERNS, HAPPINESS, CARE_NEEDS, CAREGIVER_EXPERIENCE } =
  REPORT_TYPES;

const REPORT_TABS = [
  { value: SYMPTOMS, label: 'Symptoms' },
  { value: CONCERNS, label: 'Concerns' },
  { value: HAPPINESS, label: 'Happiness' },
  { value: CARE_NEEDS, label: 'Care Needs Tool' },
  { value: CAREGIVER_EXPERIENCE, label: 'Caregiver Experience' },
];

const PATIENT_REPORT_TABS = [CONCERNS, SYMPTOMS, CARE_NEEDS, HAPPINESS];
const CAREGIVER_REPORT_TABS = [CAREGIVER_EXPERIENCE];

const getReportTabs = (accountType, isImpersonationMode, roles = []) => {
  if (accountType === AccountType.Patient) {
    let tabs = REPORT_TABS.filter((tab) =>
      PATIENT_REPORT_TABS.includes(tab.value)
    );

    // if there is no HealthTracker - hide Concerns, Symptoms
    // if there is no CareNeedsTool - hide Care Needs
    if (isImpersonationMode) {
      const allowedRoleNames = Object.entries(roles)
        .filter(([_roleType, roleMode]) => roleMode !== PermissionAllow.Hide)
        .map(([roleType]) => roleType);

      if (!allowedRoleNames.includes(PermissionType.HealthTracker)) {
        tabs = tabs.filter(
          (tab) => tab.value !== CONCERNS && tab.value !== SYMPTOMS
        );
      }

      if (!allowedRoleNames.includes(PermissionType.CareNeedsTool)) {
        tabs = tabs.filter((tab) => tab.value !== CARE_NEEDS);
      }
    }

    return tabs;
  }

  if (accountType === AccountType.CareGiver) {
    return REPORT_TABS.filter((tab) =>
      CAREGIVER_REPORT_TABS.includes(tab.value)
    );
  }

  return [];
};

export default getReportTabs;
