export const chartColors = {
  primary: '#009c47',

  green: '#52A62D',
  blue: '#019BC2',
  purple: '#9F2080',
  golden: '#FFE40C',
  pink: '#F93996',
  cyan: '#016A89',
};
