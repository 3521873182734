export const SET_OPEN_MENU = '@global/openMenu';
export const openMenu = (isOpened) => ({
  type: SET_OPEN_MENU,
  payload: isOpened,
});

export const CLOSE_WELCOME_MODALS = '@global/closeWelcomeModals';
export const closeWelcomeModals = () => ({
  type: CLOSE_WELCOME_MODALS,
});

export const SHOW_ADDED_PROMPT = '@global/showAddedPrompt';
export const showAddedPrompt = (
  addedSuggestionsCount,
  addedPromptFormName
) => ({
  type: SHOW_ADDED_PROMPT,
  payload: {
    isShowedAddedPrompt: true,
    addedSuggestionsCount,
    addedPromptFormName,
  },
});

export const HIDE_ADDED_PROMPT = '@global/hideAddedPrompt';
export const hideAddedPrompt = () => ({
  type: HIDE_ADDED_PROMPT,
  payload: {
    isShowedAddedPrompt: false,
    addedSuggestionsCount: 0,
    addedPromptFormName: '',
  },
});

export const SET_PROFILE_INFO = '@global/setProfileInfo';
export const setProfileInfo = (profileInfo) => ({
  type: SET_PROFILE_INFO,
  payload: profileInfo,
});

export const SET_SHOW_ALERT = '@global/showAlert';
export const showAlert = (isShowedAlert, alertMessage) => ({
  type: SET_SHOW_ALERT,
  payload: { isShowedAlert: isShowedAlert, alertMessage: alertMessage },
});

export const SET_SHOW_ERROR_MODAL = '@global/showErrorModal';
export const showErrorModal = (errorMessage) => {
  return {
    type: SET_SHOW_ERROR_MODAL,
    payload: {
      isShowedError: !!errorMessage,
      errorMessage: errorMessage || '',
    },
  };
};

export const SET_SHOW_TIP_POPUP = '@global/tipPopup';
export const showTipPopup = (
  isShowedPopup,
  popupText = '',
  popupIcon = null,
  popupBgColor = null
) => ({
  type: SET_SHOW_TIP_POPUP,
  payload: { isShowedPopup, popupText, popupIcon, popupBgColor },
});

export const showQuestionPrompt = (isShowed) => ({
  type: SET_SHOW_ALERT,
  payload: {
    isShowedAlert: isShowed,
    alertMessage: `
      Thank you for personalizing your CareHub! You can come back any time to
      make changes. Next, please fill in your About Me section so we can
      personalize the information we send you.`,
  },
});
