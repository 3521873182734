import React from 'react';
import Modal from 'react-modal';

import useAppState, { useAppDispatch } from '~/appState';
import { hideAddedPrompt } from '~/actions/global';
import { ReactComponent as Bell } from '~/assets/svg/Bell.svg';
import {
  getFormPopupContent,
  POPUP_FORMS,
} from '~/consts/suggestionsAndTips/formPopupsContent';
import './styles.scss';

function AddResourceConfirmModal() {
  const {
    global: {
      isShowedAddedPrompt,
      addedSuggestionsCount,
      addedPromptFormName,
      screenName,
      accountType,
    },
    permissions: { isImpersonationMode },
  } = useAppState();
  const dispatch = useAppDispatch();

  function handleCloseClick() {
    dispatch(hideAddedPrompt());
  }

  const contentType = addedSuggestionsCount > 0 ? 'positive' : 'negative';

  let formContent = getFormPopupContent(
    accountType,
    addedPromptFormName,
    handleCloseClick,
    isImpersonationMode && screenName
  )[contentType];

  return (
    <Modal
      className="modal-resource"
      isOpen={isShowedAddedPrompt}
      onRequestClose={handleCloseClick}
      shouldCloseOnOverlayClick={true}
    >
      <button className="close-modal" onClick={handleCloseClick}>
        <span>&times;</span>
      </button>
      <div className="add_resource-modal-row">
        {addedPromptFormName !== POPUP_FORMS.ABOUT_ME &&
          addedPromptFormName !== POPUP_FORMS.REMINDERS &&
          addedPromptFormName !== POPUP_FORMS.PROFILE &&
          addedPromptFormName !== POPUP_FORMS.CAREGIVING_NEEDS && (
            <span>
              <Bell />
            </span>
          )}
        <div className="add_resource-modal-description">{formContent}</div>
      </div>
    </Modal>
  );
}

export default AddResourceConfirmModal;
