import { useEffect } from 'react';

import { useLocation } from 'react-router';

const useScrollToHash = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [location]);
};

export default useScrollToHash;
