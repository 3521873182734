import { useMemo } from 'react';
import useAppState, { useAppDispatch } from '~/appState';
import { addSuggestion } from '~/actions/library';
import { useAddSuggestionsRequest } from '~/hooks/requests';
import { showAddedPrompt } from '~/actions/global';
import { POPUP_FORMS } from '~/consts/suggestionsAndTips/formPopupsContent';

function useSuggestions(formName = POPUP_FORMS.DEFAULT, callback = () => {}) {
  const {
    library: { userSuggestions },
    suggestions: { suggestions },
  } = useAppState();
  const dispatch = useAppDispatch();
  const addedSuggestions = useMemo(() => {
    let addedSuggestions = {};
    for (let i = 0; i < userSuggestions.length; i++) {
      addedSuggestions[userSuggestions[i].suggestionId] = true;
    }
    return addedSuggestions;
  }, [userSuggestions]);

  const { errors: _errors, sendRequest } = useAddSuggestionsRequest(
    handleSuggestionSubmitSuccess
  );

  function handleSuggestionSubmitSuccess(userSuggestion) {
    callback(userSuggestion);
    dispatch(addSuggestion(userSuggestion));
    dispatch(showAddedPrompt(userSuggestion, formName));
  }

  const handleAddSuggestions = (resources = []) => {
    if (resources.length) {
      sendRequest(resources);
    } else {
      // dispatch(showAddedPrompt(0, formName)); // disable modal
    }
  };

  return {
    suggestions,
    addedSuggestions,
    handleAddSuggestions,
  };
}

export default useSuggestions;
