import {
  SET_IMPERSONATION_MODE,
  SET_ABLE_IMPERSONATE,
} from '~/actions/permissions';
import {
  keysOfPermissionType,
  PermissionAllow,
  PermissionType,
} from '~/consts';

const initialState = {
  isAbleToImpersonate: false,
  isImpersonationMode: false,
  allowedUrls: [],
  roles: {
    [PermissionType.Welcome]: PermissionAllow.Read,
  },
  patientsList: [],
};

const getAllowedUrls = (roles) => {
  // TODO: use const ImpersonationAllowedURLs?
  const allowedUrls = ['', 'welcome', 'reports'];

  const allowedFeatures = roles.map((role) =>
    role.replace(PermissionAllow.Write, '').replace(PermissionAllow.Read, '')
  );

  if (allowedFeatures.includes(PermissionType.Library)) {
    allowedUrls.push('library');
  }
  if (allowedFeatures.includes(PermissionType.JournalEntry)) {
    allowedUrls.push('notes', 'add-note');
  }
  if (allowedFeatures.includes(PermissionType.FinanceTracker)) {
    allowedUrls.push('expenses');
  }
  if (allowedFeatures.includes(PermissionType.HealthTracker)) {
    allowedUrls.push('health-tracker');
  }
  if (allowedFeatures.includes(PermissionType.MedicationTracker)) {
    allowedUrls.push('medication-tracker');
  }
  if (allowedFeatures.includes(PermissionType.CareNeedsTool)) {
    allowedUrls.push('care-needs-tool');
  }
  if (allowedFeatures.includes(PermissionType.Profile)) {
    allowedUrls.push('profile');
    allowedUrls.push('profile/sharing');
  }
  if (allowedFeatures.includes(PermissionType.Calendar)) {
    allowedUrls.push('calendar');
  }

  return allowedUrls;
};

export const getHighestPermission = (formattedRoles, permissionType) => {
  const allowedModes = formattedRoles
    .filter((formattedRole) => formattedRole.includes(permissionType))
    .map((formattedRole) => formattedRole.replace(permissionType, ''));

  const isWriteMode = allowedModes.some((mode) =>
    mode.includes(PermissionAllow.Write)
  );

  if (isWriteMode) {
    return PermissionAllow.Write;
  }

  const isReadMode = allowedModes.some((mode) =>
    mode.includes(PermissionAllow.Read)
  );

  return isReadMode ? PermissionAllow.Read : PermissionAllow.Hide;
};

const mapRoles = (formattedRole) => {
  return keysOfPermissionType.map((permissionType) => ({
    [permissionType]: getHighestPermission(formattedRole, permissionType),
  }));
};

const getNewRoles = (receivedRoles, rolesFromState) => {
  const formattedStateRoles = Object.entries(rolesFromState).map(
    ([PermissionType, PermissionAllow]) => `${PermissionType}${PermissionAllow}`
  );

  const mixOfStateAndGettedRoles = [...formattedStateRoles, ...receivedRoles];
  const newRoles = {};

  mapRoles(mixOfStateAndGettedRoles).forEach((mixedRole) => {
    for (let [permissionType, mode] of Object.entries(mixedRole)) {
      newRoles[permissionType] = mode;
    }
  });

  return newRoles;
};

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMPERSONATION_MODE:
      if (action.payload.impersonateDetail !== undefined) {
        const { roles } = action.payload.impersonateDetail;

        const newState = {
          ...state,
          isImpersonationMode: action.payload.isImpersonationMode,
          roles: getNewRoles(roles, state.roles),
          allowedUrls: getAllowedUrls(roles || []),
        };

        return newState;
      } else {
        return {
          ...state,
          isImpersonationMode: action.payload.isImpersonationMode,
        };
      }
    case SET_ABLE_IMPERSONATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default permissions;
