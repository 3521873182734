const RUNTIME_ENV = process.env.RUNTIME_ENV;

const trackGTM = (eventName, data = {}) => {
  if (RUNTIME_ENV === 'prod') {
    window.dataLayer.push({
      ...data,
      event: eventName,
    });
  }
};

export default trackGTM;
