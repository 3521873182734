import { BinaryAnswers } from '~/components/profileQuestion/consts';
import { optionsBinaryAnswers } from '.';
import { emptyPopup } from '~/consts/suggestionsAndTips';

export const COMMON_QUESTIONS = {
  interestedInInclusiveness: `Are you interested in information for those
    who identify as 2SLGBTQ+?`,
  interestedSupporingChildren: `Are you interested in information about
    supporting children through the serious illness of someone they care about?`,
  interestedAboutIndigenousPeople: `Are you interested in information for
    Indigenous Peoples living with serious illness?`,
  interestedAboutGrieving: `Are you interested in information to support you
    or others who are grieving before the loss?`,
  interestedInEolInfo: `Are you interested in information about what to
    expect in the final weeks and days of life?`,
  interestedInOpioidInfo: `Medications like morphine and fentanyl are commonly
  used in serious illness for pain, shortness of breath or both.
  Are you interested in information about these types of medications?`,
};

export const commonQuestions = [
  {
    questionId: 'interestedInInclusiveness',
    questionText: COMMON_QUESTIONS.interestedInInclusiveness,
    options: optionsBinaryAnswers,
    answerIds: {
      [BinaryAnswers.yes]: {
        ...emptyPopup,
        resources: [
          'Two-Spirit and LGBTQ+Proud, Prepared, and Protected Resources',
        ],
      },
      [BinaryAnswers.no]: {
        ...emptyPopup,
      },
    },
  },
  {
    questionId: 'interestedSupporingChildren',
    questionText: COMMON_QUESTIONS.interestedSupporingChildren,
    options: optionsBinaryAnswers,
    answerIds: {
      [BinaryAnswers.yes]: {
        ...emptyPopup,
        resources: [
          'Kidsgrief.ca',
          'KidsGrief.ca - Module 2 - Talking about death and dying',
          'Talking with children and youth about death',
        ],
      },
      [BinaryAnswers.no]: {
        ...emptyPopup,
      },
    },
  },
  {
    questionId: 'interestedAboutIndigenousPeople',
    questionText: COMMON_QUESTIONS.interestedAboutIndigenousPeople,
    options: optionsBinaryAnswers,
    answerIds: {
      [BinaryAnswers.yes]: {
        ...emptyPopup,
        resources: ['LivingMyCulture.ca'],
      },
      [BinaryAnswers.no]: {
        ...emptyPopup,
      },
    },
  },
  {
    questionId: 'interestedAboutGrieving',
    questionText: COMMON_QUESTIONS.interestedAboutGrieving,
    options: optionsBinaryAnswers,
    answerIds: {
      [BinaryAnswers.yes]: {
        ...emptyPopup,
        resources: ['MyGrief.ca - Module 1 - Grieving before the loss'],
      },
      [BinaryAnswers.no]: {
        ...emptyPopup,
      },
    },
  },
  {
    questionId: 'interestedInEolInfo',
    questionText: COMMON_QUESTIONS.interestedInEolInfo,
    options: optionsBinaryAnswers,
    answerIds: {
      [BinaryAnswers.yes]: {
        ...emptyPopup,
        resources: [
          'Final weeks and days: What to expect',
          'Remaining at home: Things to consider',
          'What can be done with medications that are leftover after someone dies, or with medications that aren\'t being used anymore?',
          'As an illness progresses and end of life is near, is it reasonable to discontinue medications?',
          'Preparing families for predictable changes at end of life',
          'Explaining withholding treatment',
          'Is it painful when a person isn\'t eating or drinking anymore?',
          'How long can someone live without food or water?',
        ],
      },
      [BinaryAnswers.no]: {
        ...emptyPopup,
      },
    },
  },
  {
    questionId: 'interestedInOpioidInfo',
    questionText: COMMON_QUESTIONS.interestedInOpioidInfo,
    options: optionsBinaryAnswers,
    answerIds: {
      [BinaryAnswers.yes]: {
        ...emptyPopup,
        popupText:
          'Check out the Medication Tracker. It can help you organize and share your medication list with healthcare providers and others.',
        resources: [
          'Common concerns about opioids in palliative care',
          'What are the side effects of morphine?',
          'Does morphine make death come sooner?',
          'How should pain (Fentanyl) medication patches be safely disposed of at home?',
          'I\'m concerned about what I hear in the media about fentanyl. Is it safe to use fentanyl? How do I use my patch safely?',
          'What\'s the difference between hydromorphone, morphine, Dilaudid, and fentanyl patches?',
          'What is hydromorphone? Why might someone be switched from morphine to hydromorphone?',
          'What happens if someone has a morphine overdose? Is there a certain amount of morphine that can cause an overdose?',
          'Does someone become less sleepy once the body adjusts to a changed dose of pain medication?',
        ],
      },
      [BinaryAnswers.no]: {
        ...emptyPopup,
      },
    },
  },
];
