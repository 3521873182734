const TRACK_GTM = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  ACCESS_WELCOME: 'ACCESS_WELCOME',
  ACCESS_LIBRARY: 'ACCESS_LIBRARY',
  ACCESS_NOTES: 'ACCESS_NOTES',
  ACCESS_EXPENSES: 'ACCESS_EXPENSES',
  ACCESS_HEALTH_TRACKER: 'ACCESS_HEALTH_TRACKER',
  ACCESS_MEDICATION_TRACKER: 'ACCESS_MEDICATION_TRACKER',
  ACCESS_PROFILE: 'ACCESS_PROFILE',
  CREATE_EXPENSES: 'CREATE_EXPENSES',
  CREATE_MEDICATION: 'CREATE_MEDICATION',
  CREATE_NOTE: 'CREATE_NOTE',
  CREATE_HEALTH_TRACKER: 'CREATE_HEALTH_TRACKER',
  CREATE_HEALTH_CONCERNS: 'CREATE_HEALTH_CONCERNS',
  CREATE_HEALTH_QUESTIONS: 'CREATE_HEALTH_QUESTIONS',
  CREATE_HEALTH_PROBLEMS: 'CREATE_HEALTH_PROBLEMS',
};

export default TRACK_GTM;
