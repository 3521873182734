import React from 'react';
import Modal from 'react-modal';

import useAppState, { useAppDispatch } from '~/appState';
import { showTipPopup } from '~/actions/global';
import { ReactComponent as Bell } from '~/assets/svg/Bell.svg';

import './styles.scss';

function TipPopup() {
  const {
    global: {
      tipPopup: { isShowedPopup, popupText, popupBgColor },
    },
  } = useAppState();
  const dispatch = useAppDispatch();

  function handleCloseClick() {
    dispatch(showTipPopup(false, null));
  }

  return (
    <Modal
      className={`tip-popup ${popupBgColor || ''}`}
      overlayClassName="tip-popup-overlay"
      isOpen={isShowedPopup}
      onRequestClose={handleCloseClick}
      shouldCloseOnOverlayClick={false}
    >
      <button className="close-modal" onClick={handleCloseClick}>
        <div className="font-semibold">close</div>
        <span>&times;</span>
      </button>

      <div className="tip-popup_row">
        <span className="popup-bell">
          <Bell />
        </span>
        {/*{popupIcon && (
          <span className="alarm-icon">
            {popupIcon == 'alarm' ? <Bell /> : <i className={popupIcon} />}
          </span>
        )}*/}
        <div>
          <div
            className="tip-popup_description"
            dangerouslySetInnerHTML={{ __html: popupText }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default TipPopup;
