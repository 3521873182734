import useAppState from '~/appState';
import { PermissionAllow } from '~/consts';

const usePermission = (tabKey) => {
  const {
    permissions: {
      isImpersonationMode,
      roles,
      isAbleToImpersonate,
      allowedUrls,
    },
  } = useAppState();

  const tabPermission = tabKey ? roles[tabKey] : null;
  const isAccessAllowed =
    (tabPermission && tabPermission !== PermissionAllow.Hide) || false;
  const isWriteAllowed =
    (tabPermission && tabPermission === PermissionAllow.Write) || false;

  return {
    isImpersonationMode,
    tabPermission,
    isAbleToImpersonate,
    isAccessAllowed,
    allowedUrls,
    isWriteAllowed,
    roles,
  };
};

export default usePermission;
