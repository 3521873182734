import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import useAppState, { useAppDispatch } from '~/appState';
import Avatar from 'react-avatar';
import AvatarGroup from './avatarGroup';

import { MenuButton, Menu } from '~/components/ui';

import { LOG } from '~/consts/global';
import {
  useGetImpersonate,
  useActiveImpersonate,
  useDeActiveImpersonate,
} from '~/hooks/requests';
import {
  setImpersonationMode,
  setAbleToImpersonate,
} from '~/actions/permissions';
import { showErrorModal } from '~/actions/global';
import { TRACK_GTM, AccountType, GetImpersonatedUser } from '~/consts';
import { BannerList } from '../profileQuestion/consts';
import { login } from '~/actions/account';
import jwtDecode from 'jwt-decode';
import { trackGTM } from '~/utils';
import { setProfileImg } from '~/actions/account';
import { getAccountImageRequest } from '~/requests/profile';
import profileSvg from '~/assets/svg/profile.svg';
import './styles.scss';

function UserHeader({ history }) {
  const dispatch = useAppDispatch();
  const {
    global: {
      accountType,
      userId,
      backgroundImageId,
      profileColor,
      profileImg,
      hasProfileImage,
      userName: myName,
    },
    permissions: { patientsList },
  } = useAppState();
  const { isImpersonated, roles } = GetImpersonatedUser();

  const { sendRequest } = useGetImpersonate(handleGetImpersonateSuccess);
  const { errors: sendActiveError, sendRequest: sendImpersonateActive } =
    useActiveImpersonate(handleActiveImpersonateSuccess);
  const { errors: sendDeactiveError, sendRequest: sendImpersonateDeactive } =
    useDeActiveImpersonate(handleDeActiveImpersonateSuccess);

  const [currentItem, setCurrentItem] = useState(null);
  const [isLinkButton, setLinkButton] = useState(false);

  const handleEnableImpersonate = (item) => {
    const idx = Number(item.value) - 1;
    const impersonate = patientsList[idx];
    sendImpersonateActive(impersonate.userId);
    setCurrentItem(impersonate);
  };

  function handleActiveImpersonateSuccess(loginResult) {
    const { authToken } = loginResult;
    localStorage.setItem('authToken', authToken);
    dispatch(setImpersonationMode(true, currentItem));
    window.location.href = '/welcome';
  }

  const handleDeImpersonate = () => {
    sendImpersonateDeactive();
  };

  function handleDeActiveImpersonateSuccess(loginResult) {
    const { authToken } = loginResult;
    
    let decoded;
    
    try{
       decoded = jwtDecode(authToken);
    }catch{
      window.location.href = '/login';
      return;
    }

    localStorage.setItem('authToken', authToken);
    dispatch(setImpersonationMode(false));

    dispatch(login(loginResult, true));
    trackGTM(TRACK_GTM.LOGIN, {
      userId: decoded.user_id,
    });

    window.location.href = '/welcome';
  }

  function handleGetImpersonateSuccess(impersonateResponse) {
    LOG('handleGetImpersonateSuccess', impersonateResponse);
    if (impersonateResponse.length > 0) {
      dispatch(setAbleToImpersonate(true, impersonateResponse));
    } else {
      dispatch(setAbleToImpersonate(true, []));
    }
  }

  useEffect(() => {
    if (patientsList.length === 0 && accountType === AccountType.CareGiver) {
      sendRequest();
    }
    roles.map((item) => {
      if (item.includes('HealthTracker') || item.includes('CareNeedsTool')) {
        setLinkButton(true);
      }
    });
    // eslint-disable-next-line
  }, []);

  const renderLinkButton = () => {
    let content = <></>;
    const isBack = ['/profile', '/reports', '/calendar'].some(
      (route) =>
        history.location.pathname.endsWith(route) ||
        history.location.pathname.includes(route + '/')
    );

    if (isBack) {
      content = (
        <Link to="/welcome" className="btn btn-purpure btn-flex btn-heart">
          <img src="/img/icons/heart-search.png" alt="" /> View my Dashboard
        </Link>
      );
    } else if (!isImpersonated || (isImpersonated && isLinkButton)) {
      content = (
        <Link to="/reports" className="btn btn-purpure btn-flex btn-heart">
          <img src="/img/icons/heart.png" alt="" /> View Health Report
        </Link>
      );
    }

    return content;
  };

  useEffect(() => {
    getAccountImageRequest(userId)
      .then((blob) => dispatch(setProfileImg(blob)))
      .catch((err) => dispatch(showErrorModal(err)));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sendActiveError && dispatch(showErrorModal(sendActiveError));
  }, [sendActiveError]);

  useEffect(() => {
    sendDeactiveError && dispatch(showErrorModal(sendDeactiveError));
  }, [sendDeactiveError]);

  const renderTitle = () => {
    return (
      <div className="flex flex-wrap items-center mx-0 row-mx-15">
        <div className="page-logo">
          <Link to="/profile" className="user-avatar">
            <Avatar
              name={myName}
              round={true}
              src={
                (profileImg && URL.createObjectURL(profileImg)) || profileSvg
              }
              style={{
                overflow: 'hidden',
                backgroundColor:
                  !hasProfileImage || !profileImg
                    ? !profileColor
                      ? '#3fb4c4'
                      : profileColor
                    : '',
              }}
            />
            {(!hasProfileImage || !profileImg) && (
              <div className="avatarName" title={myName}>
                {myName.substr(0, 1).toUpperCase()}
              </div>
            )}
          </Link>

          <Link className="logo" to="/welcome">
            {`${myName}'s CareHub`}
          </Link>
          {accountType === AccountType.CareGiver ? (
            <AvatarGroup
              avatars={patientsList}
              onChange={(avatarInfo) => handleEnableImpersonate(avatarInfo)}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  const renderShareList = () => {
    return (
      <React.Fragment>
        {isImpersonated ? (
          <>
            <div className="viewas-element">
              <div className="top-line">
                <div className="container">
                  <div className="viewas-element">
                    <button
                      className="btn btn-full-blue"
                      onClick={handleDeImpersonate}
                    >
                      Back to Caregiver Account
                    </button>
                    <div className="viewas-title">&larr; Switch CareHubs </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderShareList()}
      <div
        className={`header${
          history.location.pathname === '/' ? ' main-page-header' : ''
        }`}
        style={{
          background: `url(${
            BannerList[backgroundImageId || 0].url
          }) center center no-repeat`,
        }}
      >
        <div className="container">
          <div className="container-nav">
            <div className="nav-block">
              {renderShareList()}
              <Link className="header-nav-item" to="/help">
                <img src="/img/icons/icon-help.png" alt="" />
                <span>Help</span>
              </Link>
              <MenuButton />
            </div>

            <div className="nav-block-resp">{renderShareList()}</div>
            {renderLinkButton()}
          </div>

          {renderTitle()}
          <Menu />
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(UserHeader);
