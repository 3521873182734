import fetch from '~/utils/fetch';
import { WELCOME_QUESTION_URL, WELCOME_QUESTION_SAVE_URL } from '~/consts/urls';
import { buildUrlParamsString } from '~/utils';

export const postWelcomeQuestionRequest = data =>
  fetch(WELCOME_QUESTION_SAVE_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const getWelcomeQuestionRequest = (data) => {
  const params = data ? buildUrlParamsString(data) : '';
  return fetch(`${WELCOME_QUESTION_URL}?${params}`, {
    method: 'GET',
  });
};
