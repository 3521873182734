import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useAppDispatch } from '~/appState';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import SuggestionsLoading from '~/components/modals/suggestionsLoading';
import AddResourceConfirmModal from './modals/addResourceConfirmModal';
import AlertModal from './modals/alertModal';
import ErrorModal from './modals/errorModal';
import { GetImpersonatedUser } from '~/consts';
import { setImpersonationMode } from '~/actions/permissions';
import TipPopup from './modals/tipPopup';
import { useHistory } from 'react-router-dom';

function App() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const { isImpersonated, roles } = GetImpersonatedUser();
    dispatch(setImpersonationMode(isImpersonated, { roles: roles }));
    // eslint-disable-next-line
  }, []);

  // Calendar events
  useEffect(() => {
    if (!window.editEvent) {
      window.editEvent = (eventId) => history.push('/calendar/edit/' + eventId);
    }
    if (!window.deleteEvent) {
      window.deleteEvent = (eventId) =>
        history.push('/calendar/delete/' + eventId);
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes />
      <SuggestionsLoading />
      <AddResourceConfirmModal />
      <AlertModal />
      <ErrorModal />
      <TipPopup />
    </React.Fragment>
  );
}

export default hot(module)(App);
