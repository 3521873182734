import 'whatwg-fetch';
import { LOG } from '~/consts/global';

const errorMessage = 'An error has occurred, please try again.';

const isAuthenticated = () => localStorage.getItem('authToken');
const getAuthHeader = () => {
  let authToken = localStorage.getItem('authToken');
  return authToken ? { Authorization: 'Bearer ' + authToken } : {};
};
const getOptions = (options) => ({
  ...options,
  headers: isAuthenticated
    ? {
        'Access-Control-Allow-Origin': '*',
        credentials: 'include',
        ...options.headers,
        ...getAuthHeader(),
      }
    : {
        'Access-Control-Allow-Origin': '*',
        credentials: 'include',
        ...options.headers,
      },
});

export const fetchDataWithoutAuthorization = (url, opts) => {
  return fetch(url, opts)
    .catch((error) => {
      LOG('<<< fetchAuthError: ', url, opts['method'], error);
      return Promise.reject([errorMessage, error.message, url]);
    })
    .then((response) => {
      LOG('<<< fetchAuthRes: ', url, opts['method'], response);
      if (!response || (response.status >= 200 && response.status < 300)) {
        return Promise.resolve(response);
      } else {
        LOG('<<<error');
        return Promise.reject([
          errorMessage,
          response.statusText,
          response.status,
        ]);
      }
    })
    .then((response) => (response ? response.json() : null))
    .then((json) => {
      if (json.errors) {
        const error =
          json.errors && json.errors.length > 0 ? json.errors : [errorMessage];
        LOG('<<<json parse error:', error);
        return Promise.reject(error);
      }
      LOG('<<< success', json.returned);
      return Promise.resolve(json);
    });
};

export default (url, opts) => {
  const options = getOptions(opts);
  LOG('=== fetch: ', url, opts['method'], opts['body']);

  return fetch(url, options)
    .catch((error) => {
      LOG('<<< fetchError: ', url, opts['method'], error);
      return Promise.reject([errorMessage, error.message, url]);
    })
    .then((response) => {
      LOG('<<< fetch: ', url, opts['method'], response);
      if (!response || (response.status >= 200 && response.status < 300)) {
        return Promise.resolve(response);
      } else {
        return Promise.reject([
          errorMessage,
          response.statusText,
          response.status,
        ]);
      }
    })
    .then((response) => (response ? response.json() : null))
    .then((json) => {
      if (json.errors) {
        const error =
          json.errors && json.errors.length > 0 ? json.errors : [errorMessage];
        LOG('<<<json parse error:', error);
        return Promise.reject(error);
      }
      LOG('<<< success', json.returned);
      return Promise.resolve(json.returned);
    });
};

export const fetchBlob = (url, opts) => {
  const options = getOptions(opts);
  LOG('=== fetchBlob: ', url, opts['method'], opts['body']);

  return fetch(url, options)
    .catch((error) => {
      LOG('<<< fetchBlobError: ', url, opts['method'], error);
      return Promise.reject([errorMessage, error.message, url]);
    })
    .then((response) => {
      LOG('<<< fetchBlob: ', url, opts['method'], response);
      if (!response || (response.status >= 200 && response.status < 300)) {
        return Promise.resolve(response);
      } else {
        return Promise.reject([
          errorMessage,
          response.statusText,
          response.status,
        ]);
      }
    })
    .then((response) => response.blob());
};
