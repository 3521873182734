import React from 'react';
import useAppState, { useAppDispatch } from '~/appState';
import { openMenu } from '~/actions/global';
import './styles.scss';

function MenuButton() {
  const {
    global: { isOpened },
  } = useAppState();
  const dispatch = useAppDispatch();

  return (
    <button
      // this id is used in useOutsideClick() to prevent reopening
      id="navbar-menu-button"
      className="navbar-menu"
      onClick={() => dispatch(openMenu(!isOpened))}
    >
      <i className="fas fa-bars" />
      <span>Menu</span>
    </button>
  );
}

export default MenuButton;
