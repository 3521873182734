import React from 'react';
import { PermissionType } from './permissions';

import { ReactComponent as Welcome } from '~/assets/svg/Welcome.svg';
import { ReactComponent as Library } from '~/assets/svg/Library.svg';
import { ReactComponent as Notes } from '~/assets/svg/Notes.svg';
import { ReactComponent as Expenses } from '~/assets/svg/Expenses.svg';
import { ReactComponent as Health } from '~/assets/svg/Health.svg';
import { ReactComponent as Medication } from '~/assets/svg/Medication.svg';
import { ReactComponent as CareNeeds } from '~/assets/svg/care-needs-white.svg';

const HOME = {
  title: 'Dashboard',
  url: 'welcome',
};

const LIBRARY = {
  title: 'Library',
  url: 'library',
};

const NOTES = {
  title: 'Notes',
  url: 'notes',
};

const EXPENSES = {
  title: 'Expense Tracker',
  url: 'expenses',
};

const MY_PROFILE = {
  title: 'My Profile',
  url: 'profile',
};

const CAREGIVING_NEEDS = {
  title: 'Caregiving Needs',
  url: 'profile/caregiving-needs',
};

const REMINDERS = {
  title: 'Reminders',
  url: 'profile/reminders',
};

const MY_PATIENTS = {
  title: 'Who I Care For',
  url: 'profile/patients',
};

const HEALTH_REPORTS = {
  title: 'Health Reports',
  url: 'reports',
};

const CALENDAR = {
  title: 'Calendar',
  url: 'calendar',
};

const HEALTH_TRACKER = {
  title: 'Health Tracker',
  url: 'health-tracker',
};

const MEDICATION_TRACKER = {
  title: 'Medication Tracker',
  url: 'medication-tracker',
};

const CARE_NEEDS_TOOL = {
  title: 'Care Needs Tool',
  url: 'care-needs-tool',
};

const SHARED_ACCESS = {
  title: 'Sharing',
  url: 'profile/sharing',
};

export const CAREGIVER_MENU = [
  HOME,
  MY_PROFILE,
  CAREGIVING_NEEDS,
  MY_PATIENTS,
  REMINDERS,
  CALENDAR,
  LIBRARY,
  NOTES,
  EXPENSES,
  HEALTH_REPORTS,
];

export const PATIENT_MENU = [
  HOME,
  MY_PROFILE,
  SHARED_ACCESS,
  REMINDERS,
  CALENDAR,
  LIBRARY,
  NOTES,
  EXPENSES,
  HEALTH_TRACKER,
  MEDICATION_TRACKER,
  CARE_NEEDS_TOOL,
  HEALTH_REPORTS,
];

export const IMPERSONATED_MENU = [
  HOME,
  MY_PROFILE,
  SHARED_ACCESS,
  CALENDAR,
  LIBRARY,
  NOTES,
  EXPENSES,
  HEALTH_TRACKER,
  MEDICATION_TRACKER,
  CARE_NEEDS_TOOL,
  HEALTH_REPORTS,
];

export const DashboardTabList = [
  {
    tabKey: PermissionType.Welcome,
    label: 'Welcome',
    url: 'welcome',
    icon: <Welcome />,
  },
  {
    tabKey: PermissionType.Library,
    label: 'Library',
    url: 'library',
    icon: <Library />,
  },
  {
    tabKey: PermissionType.JournalEntry,
    label: 'Notes',
    url: 'notes',
    icon: <Notes />,
  },
  {
    tabKey: PermissionType.FinanceTracker,
    label: 'Expense Tracker',
    url: 'expenses',
    icon: <Expenses />,
  },
  {
    tabKey: PermissionType.HealthTracker,
    label: 'Health tracker',
    url: 'health-tracker',
    icon: <Health />,
  },
  {
    tabKey: PermissionType.MedicationTracker,
    label: 'Medication tracker',
    url: 'medication-tracker',
    icon: <Medication />,
  },
  {
    tabKey: PermissionType.CareNeedsTool,
    label: 'Care Needs Tool',
    url: 'care-needs-tool',
    icon: <CareNeeds />,
  },
];

export const PatientExceptionUrls = ['profile/caregiving-needs'];
export const CaregiverExceptionUrls = [
  'health-tracker',
  'medication-tracker',
  'mobility-tracker',
  'care-needs-tool',
];
