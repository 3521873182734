import React from 'react';
import { withRouter } from 'react-router';
import { Menu } from '~/components/ui';
import './styles.scss';

function GuestHeader({ history }) {
  return (
    <div
      className={`header${
        history.location.pathname === '/' ? ' main-page-header' : ''
      }`}
    >
      <div className="container">
        <Menu />
      </div>
    </div>
  );
}

export default withRouter(GuestHeader);
