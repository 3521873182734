import jwtDecode from 'jwt-decode';
import {
  SET_OPEN_MENU,
  CLOSE_WELCOME_MODALS,
  SHOW_ADDED_PROMPT,
  HIDE_ADDED_PROMPT,
  SET_PROFILE_INFO,
  SET_SHOW_ALERT,
  SET_SHOW_ERROR_MODAL,
  SET_SHOW_TIP_POPUP,
} from '~/actions/global';
import { SET_AUTHENTICATED, LOGIN, SET_PROFILE_IMG } from '~/actions/account';

const initialState = {
  isOpened: false,
  isShowedAddedPrompt: false,
  addedSuggestionsCount: 0,
  addedPromptFormName: '',
  isShowedAlert: false,
  alertMessage: '',
  isShowedError: false,
  errorMessage: '',
  tipPopup: {
    isShowedPopup: false,
    popupText: null,
    popupIcon: null,
    popupBgColor: null,
  },
  suggestions: [],
};

function global(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_MENU:
      return {
        ...state,
        isOpened: action.payload,
        isLogin: action.payload,
      };
    case SET_PROFILE_IMG: {
      const { profileImg } = action.payload;
      return {
        ...state,
        profileImg,
      };
    }
    case SET_AUTHENTICATED:
      const { authToken, isLogin } = action.payload;

      let decoded;

      try {
        decoded = jwtDecode(authToken);
      } catch {
        window.location.href = '/login';
        return;
      }

      return {
        ...state,
        userName: decoded.user_name,
        userId: decoded.user_id,
        postalCode: decoded.postal_code,
        accountType: decoded.account_type,
        showWelcomeModals: isLogin,
        backgroundImageId: decoded.background_id,
        profileColor: decoded.profile_color,
        hasProfileImage: decoded.has_profile_image
          ? decoded.has_profile_image.toLowerCase() == 'True'.toLowerCase()
          : undefined,
      };
    case LOGIN: {
      const {
        loginResult: { authToken },
        isLogin,
      } = action.payload;
      const decoded = jwtDecode(authToken);
      return {
        ...state,
        userName: decoded.user_name,
        userId: decoded.user_id,
        postalCode: decoded.postal_code,
        accountType: decoded.account_type,
        hasProfileImage: decoded.has_profile_image
          ? decoded.has_profile_image.toLowerCase() == 'True'.toLowerCase()
          : undefined,
        showWelcomeModals: isLogin,
      };
    }
    case CLOSE_WELCOME_MODALS:
      return {
        ...state,
        showWelcomeModals: false,
      };
    case SHOW_ADDED_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    case HIDE_ADDED_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PROFILE_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SHOW_ALERT:
      return {
        ...state,
        isShowedAlert: action.payload.isShowedAlert,
        alertMessage: action.payload.alertMessage,
      };
    case SET_SHOW_ERROR_MODAL:
      return {
        ...state,
        isShowedError: action.payload.isShowedError,
        errorMessage: action.payload.errorMessage,
      };
    case SET_SHOW_TIP_POPUP:
      return {
        ...state,
        tipPopup: { ...action.payload },
      };
    default:
      return state;
  }
}

export default global;
