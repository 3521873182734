export const QuestionTip1 =
  'We ask this to collect basic demographics for evaluation and quality improvement.';
export const QuestionTip2 =
  'You can answer this question and share it with your healthcare team if you choose.' +
  ' CareHub administrators will not be able to see your answer.';
export const QuestionTip3 =
  'We ask this to tailor the resources, programs and services that are suggested for you,' +
  ' and to collect basic demographics for evaluation and quality improvement.';
export const QuestionTip4 =
  'We ask this to tailor the resources, programs and services that are suggested for you.';
export const QuestionTip5 =
  'This is the name that will appear to you and the people you are connected with.';
export const QuestionTip6 =
  'If you are a caregiver, you must have the consent of the person you are caring for ' +
  '(or can provide consent on behalf of that person) in order to share their CareHub ' +
  'with others (as per the Legal Notices / Privacy Policy).';

export const RequiredAlert1 =
  'Please note that the information, resources, programs and services generated by CareHub' +
  ' are for caring for adults. If you are caring for a child or someone under 18, please' +
  ' visit CaringTogether.life for information, resources, programs and services for caring' +
  ' for a seriously ill child.';
//https://caringtogether.life/

export const YourAges = {
  under12: 'Under 12',
  '12-18': '12 - 18',
  '19-44': '19 - 44',
  '45-64': '45 - 64',
  '65-79': '65 - 79',
  '80-89': '80 - 89',
  '90andOver': '90 and over',
};

export const Gender = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
};

export const TimeHealthIssues = {
  '6monthsOrLess': '6 months or less',
  '6-12months': '6 - 12 months',
  OverAYear: 'Over a year',
};

export const CareMonths = {
  '0-6months': '0 - 6 months',
  '6-12months': '6 - 12 months',
  Over1Year: 'Over 1 year',
};

export const CareDays = {
  '1-2days': '1 - 2 days',
  '3-4days': '3 - 4 days',
  '4-6days': '4 - 6 days',
  '7days': '7 days',
};

export const CareHours = {
  'Less than 1 hour': 'Less than 1 hour',
  '1 - 3 hours': '1 - 3 hours',
  '3 - 6 hours': '3 - 6 hours',
  'Over 6 hours': 'Over 6 hours',
};

export const BinaryAnswers = {
  yes: 'Yes',
  no: 'No',
};

export const BinaryAnswersAndUnsure = {
  ...BinaryAnswers,
  unsure: 'Unsure',
};

export const WorkTimes = {
  YesFullTime: 'Yes - Full time',
  YesPartTime: 'Yes - Part time',
  No: 'No',
};

export const CarePeople = {
  PartnerOrSpouse: 'Partner or Spouse',
  Parent: 'Parent',
  Friend: 'Friend',
  Sibling: 'Sibling',
  InLaw: 'In-law',
  Child: 'Child',
  Grandchild: 'Grandchild',
};
export const CaringForOtherOption = 'Other';
export const CarePeopleChild = '6';
export const CarePeopleGrandchild = '7';

export const BannerList = [
  { title: 'Default', url: '/img/banner/default.jpg' },
  { title: 'Bird', url: '/img/banner/bird.jpg' },
  { title: 'Seaside', url: '/img/banner/1.jpg' },
  { title: 'Flower', url: '/img/banner/2.jpg' },
  { title: 'Sunrise', url: '/img/banner/3.jpg' },
  { title: 'Sky', url: '/img/banner/4.jpg' },
  { title: 'Nature', url: '/img/banner/5.jpg' },
  { title: 'Forest', url: '/img/banner/6.jpg' },
  { title: 'Other', url: '/img/banner/7.jpg' },
];

const likeLearnMore = 'Would you like to learn more about it?';
export const patientQuestionSuggestions = {
  mainIssues: {
    title: likeLearnMore,
    // eslint-disable-next-line
    condition: function condition(value) {
      if (
        value &&
        value.filter((mi) => mi && mi.toLowerCase().includes('lung')).length > 0
      ) {
        return 'value';
      }
    },
    options: { value: [3] },
  },
  minorIssues: {
    title: likeLearnMore,
    // eslint-disable-next-line
    condition: function condition(value) {
      if (
        value &&
        value.filter((mi) => mi && mi.toLowerCase().includes('insomnia'))
          .length > 0
      ) {
        return 'value';
      }
    },
    options: { value: [44, 54] },
  },
  childrenAge: {
    title: likeLearnMore,
    // eslint-disable-next-line
    condition: function condition(value) {
      if (
        value &&
        (value.includes('1') || value.includes('2') || value.includes('3'))
      ) {
        return '1';
      }
    },
    options: { 1: [52], 2: [52], 3: [52] },
  },
};
