export const SET_EVENTS = '@calendar/setEvents';
export const setEvents = (events) => ({
  type: SET_EVENTS,
  payload: events.map((e) => {
    e.text = e.title;
    // remove milliseconds from date string:
    // 2022-02-14T04:54:32.0127278 -> 2022-02-14T04:54:32
    e.start = new Date(e.start.split('.')[0] + 'Z');
    e.end = new Date(e.end.split('.')[0] + 'Z');
    return e;
  }),
});

export const ADD_EVENT = '@calendar/addEvent';
export const addEvent = (event) => ({
  type: ADD_EVENT,
  payload: {
    ...event,
    start: new Date(event.start),
    end: new Date(event.end),
  },
});

export const SET_CURRENT_EVENTS_IDS = '@calendar/setCurrentEventsIds';
export const setCurrentEventsIds = (date) => ({
  type: SET_CURRENT_EVENTS_IDS,
  payload: date,
});
