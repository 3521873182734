import CONFIG from '~/utils/config';

const baseUrl = CONFIG.apiBaseUrl;
const cvhUrl = `https://cvh.venuiti.com/umbraco/api/ContentApi`;

export const ACCOUNT_URL = `${baseUrl}/Account`;
export const REGISTER_URL = `${ACCOUNT_URL}/Register`;
export const LOGIN_URL = `${ACCOUNT_URL}/Login`;
export const FORGOT_PASSWORD_URL = `${ACCOUNT_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${ACCOUNT_URL}/reset-password`;
export const INTAKE_MANAGER_URL = `${baseUrl}/IntakeManager`;
export const WELCOME_QUESTION_URL = `${baseUrl}/reports/questions`;
export const WELCOME_QUESTION_SAVE_URL = `${baseUrl}/question/welcome`;
export const HEALTH_TRACKER_URL = `${baseUrl}/HealthTracker`;
export const HEALTH_CONCERNS_URL = `${HEALTH_TRACKER_URL}/concerns`;
export const HEALTH_QUESTIONS_URL = `${HEALTH_TRACKER_URL}/questions`;
export const HEALTH_HEALTH_URL = `${HEALTH_TRACKER_URL}/health`;
export const SCHEDULE_NOTIFICATIONS_URL = `${HEALTH_TRACKER_URL}/ScheduleNotifications`;
export const QUESTIONS_URL = `${baseUrl}/Question`;
export const SUGGESTIONS_URL = `${baseUrl}/Suggestion`;
export const PROGRAM_AND_SERVICE_CATEGORIES_URL = `${cvhUrl}/GetProgramAndServiceCategories`;
export const PROGRAM_AND_SERVICE_URL = `${cvhUrl}/GetProgramAndServiceByDistanceInKm`;
export const ADD_SUGGESTION_URL = `${SUGGESTIONS_URL}/Add`;
export const GET_SUGGESTIONS_URL = `${SUGGESTIONS_URL}/All`;
export const REPORT_URL = `${baseUrl}/Reports/GetReport`;
export const GRAPH_URL = `${baseUrl}/Reports/GetGraph`;
export const REPORT_CARE_NEEDS_URL = `${baseUrl}/reports/care-needs`;
export const EVENTS_URL = `${baseUrl}/Calendar/Events`;
export const ALL_EVENTS_URL = `${baseUrl}/Calendar/AllEvents`;
export const PROFILE_URL = `${ACCOUNT_URL}/Profile`;
export const ACCOUNT_IMAGE_URL = `${ACCOUNT_URL}/image`;
export const MEDICATION_URL = `${baseUrl}/medication`;
export const JOURNAL_ENTRY = `${baseUrl}/journalEntry`;
export const FINANCE_RECORD_URL = `${baseUrl}/financeRecord`;
export const FINANCE_TABS_URL = `${baseUrl}/FinanceTracker`;
export const REMINDERS_URL = `${baseUrl}/Reminders`;

export const IMPERSONATE_URL = `${ACCOUNT_URL}/impersonate`;
export const IMPERSONATE_NAME_URL = `${ACCOUNT_URL}/impersonation-name`;
export const DEIMPERSONATE_URL = `${ACCOUNT_URL}/deimpersonate`;
export const WELCOME_URL = `${ACCOUNT_URL}/welcome`;
export const INVITE_URL = `${ACCOUNT_URL}/invite`;
export const CREATE_PATIENT_URL = `${ACCOUNT_URL}/createPatient`;
export const SHARED_ACCOUNT_URL = `${ACCOUNT_URL}/shared-with`;
export const DELETE_SHARED_ACCOUNT_URL = `${ACCOUNT_URL}/delete-shared-access`;

export const SUBSCRIBE_URL = '#';
export const FACEBOOK_URL = 'https://www.facebook.com/CanadianVirtualHospice/';
export const TWITTER_URL = 'https://twitter.com/virtualhospice?lang=en';
export const INSTAGRAM_URL =
  'https://www.instagram.com/canadianvirtualhospice/?hl=en';
export const YOUTUBE_URL =
  'https://www.youtube.com/channel/UC71m27JVXXoY_x6aLy5GQJA';
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/canadian-virtual-hospice?originalSubdomain=ca';

export const DONATE_URL =
  'https://www.canadahelps.org/en/charities/the-international-centre-for-dignity-and-palliative-care-inc/';
