import fetch, { fetchBlob } from '~/utils/fetch';
import {
  INTAKE_MANAGER_URL,
  PROFILE_URL,
  ACCOUNT_IMAGE_URL,
} from '~/consts/urls';

export const submitIntakeManagerInfoRequest = (data) =>
  fetch(INTAKE_MANAGER_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const getProfileDataRequest = () =>
  fetch(PROFILE_URL, {
    method: 'GET',
  });

export const postProfileDataRequest = (data) =>
  fetch(PROFILE_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const getAccountImageRequest = (userId) =>
  fetchBlob(ACCOUNT_IMAGE_URL + '?userId=' + userId, {
    method: 'GET',
  });

export const postAccountImageRequest = (formData) =>
  fetch(ACCOUNT_IMAGE_URL, {
    method: 'POST',
    headers: { Accept: 'application/json' },
    body: formData,
  });

export const postCaregivingNeedsQuestions = (formData) =>
  fetch(`${PROFILE_URL}/caregiving-needs-questions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: formData,
  });

export const getCaregivingNeedsQuestions = (formData) =>
  fetch(`${PROFILE_URL}/caregiving-needs-questions`, {
    method: 'GET',
  });
