import { BinaryAnswers } from '~/components/profileQuestion/consts';

export const formNames = {
  common: 'common',
  profileInfo: 'profileInfo',
};

export const emptyPopup = {
  popupText: null,
  popupIcon: null,
  popupBgColor: null,
  resources: [],
};

export const optionsBinaryAnswers = [
  {
    answerId: BinaryAnswers.yes,
    value: BinaryAnswers.yes,
    label: 'Yes',
  },
  {
    answerId: BinaryAnswers.no,
    value: BinaryAnswers.no,
    label: 'No',
  },
];
