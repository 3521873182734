import React from 'react';
import { GuestHeader, UserHeader, LandingHeader } from './header';
import Footer from './footer';
import LandingFooter from './landingFooter';

function GuestLayout(props) {
  const { children } = props;
  return (
    <React.Fragment>
      <LandingHeader isHideBottoms={true} />
      <GuestHeader />
      {children}
      <Footer />
    </React.Fragment>
  );
}

function UserLayout(props) {
  const { children } = props;
  return (
    <React.Fragment>
      <UserHeader />
      {children}
      <Footer />
    </React.Fragment>
  );
}

function LandingLayout(props) {
  const { children } = props;
  return (
    <React.Fragment>
      <LandingHeader />
      {children}
      <LandingFooter />
    </React.Fragment>
  );
}

export const getLayout = ({
  isLandingPage,
  isAuthenticated,
  keepGuestLayout,
}) => {
  if (isLandingPage) {
    return LandingLayout;
  } else if (isAuthenticated && !keepGuestLayout) {
    return UserLayout;
  }

  return GuestLayout;
};
