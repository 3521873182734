import React from 'react';
import Avatar from 'react-avatar';
import { ACCOUNT_IMAGE_URL } from '~/consts/urls';

function AvatarGroup({ avatars, onChange }) {
  const handleChange = (avatarInfo) => {
    onChange(avatarInfo);
  };
  return (
    <div className="carehub-avatar-group">
      {avatars.map((avatar, idx) => {
        const value = idx + 1;
        const avatarInfo = {
          value: value.toString(),
          label: avatars[idx].name,
        };
        return (
          <div
            className="carehub-avatar"
            key={idx}
            onClick={() => handleChange(avatarInfo)}
            role="button"
            tabIndex="-1"
          >
            <Avatar
              className="sub-avatar"
              name={avatar.name}
              size="50"
              round={true}
              src={`${ACCOUNT_IMAGE_URL}?userId=${avatar.userId}`}
              style={{
                backgroundColor:
                  avatar.profileColor === null
                    ? '#3fb4c4'
                    : avatar.profileColor,
                border: 'solid 4px #fff',
              }}
            />
            {!avatar.hasProfileImage && (
              <div className="avatarName" title={avatar.name}>
                {avatar.name.substr(0, 1).toUpperCase()}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
export default AvatarGroup;
