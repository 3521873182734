const SYMPTOMS = 'symptoms';
const CONCERNS = 'concerns';
const HAPPINESS = 'happiness';
const CARE_NEEDS = 'care-needs';
const CAREGIVER_EXPERIENCE = 'caregiver-experience';

export default {
  SYMPTOMS,
  CONCERNS,
  HAPPINESS,
  CARE_NEEDS,
  CAREGIVER_EXPERIENCE,
};
