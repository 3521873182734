import 'babel-polyfill';
import React, { Children } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter as Router } from 'react-router-dom';
import { StateProvider } from './appState';
import App from '~/components/app';
import 'react-day-picker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import 'react-toastify/dist/ReactToastify.css';
import '~/assets/css/normalize.css';
import '~/assets/css/global.css';
import '~/assets/css/responsive.css';
import '~/assets/css/fontawesome.css';
import '~/assets/css/lib-style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isJwtExpired } from 'jwt-check-expiration';

Modal.setAppElement('#root');

// Intercepts every fetch call to check on token validity
const { fetch: origFetch } = window;
window.fetch = async (...args) => {
  const response = await origFetch(...args);

  if (response.status == 401) {
    localStorage.removeItem('authToken');

    if (window.location.pathname != "/login") {
      window.location = `/login?return-url=${window.location.pathname}`;
    } else {
      window.location = `/login`
    }

    return;
  }

  return response;
}

function AuthExpirationCheck(props) {
  const token = localStorage.getItem("authToken");

  let expired = true;

  try {
    expired = isJwtExpired(token)
  } catch { }

  if (!token || expired) {
    localStorage.removeItem('authToken');
  }

  return props.children;
}

ReactDOM.render(
  <AppContainer>
    <AuthExpirationCheck>
      <Router>
        <StateProvider>
          <App />
        </StateProvider>
      </Router>
    </AuthExpirationCheck>
  </AppContainer>,
  document.getElementById('root')
);
