import fetch from '~/utils/fetch';
import { FINANCE_TABS_URL } from '~/consts/urls';

const financeTypeValues = {
  notRelatedTravelDto: 1,
  relatedTravelDto: 2,
  otherExpensesDto: 3,
  attendantCareDto: 4,
};

export const getFinances = (startDate, endDate, financeType, searchTerm) => {
  let url = `${FINANCE_TABS_URL}/Export?`;

  if (startDate) {
    url = `${url}&startDate=${startDate}`;
  }
  if (endDate) {
    url = `${url}&endDate=${endDate}`;
  }
  if (financeType) {
    url = `${url}&financeType=${financeType}`;
  }
  if (searchTerm) {
    url = `${url}&searchTerm=${searchTerm}`;
  }

  return fetch(url, {
    method: 'GET',
  })
    .then((response) => {
      const convertedResponse = Object.entries(response || {}).reduce(
        (result, [financeType, financesArr]) => [
          ...result,
          ...financesArr.map((financeItem) => ({
            ...financeItem,
            financeType: financeTypeValues[financeType],
          })),
        ],
        []
      );

      return Promise.resolve(convertedResponse);
    })
    .catch((err) => Promise.reject(err));
};

export const getFinance = (id, financeType) => {
  let url = `${FINANCE_TABS_URL}/${id}?`;

  if (financeType) {
    url = `${url}&financeType=${financeType}`;
  }

  return fetch(url, {
    method: 'GET',
  });
};

export const trackFinanceRequest = (data) =>
  fetch(FINANCE_TABS_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const updateFinance = (id, data) =>
  fetch(`${FINANCE_TABS_URL}/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

export const deleteFinance = (id, financeType) => {
  const url = `${FINANCE_TABS_URL}/${id}?&financeType=${financeType}`;

  return fetch(url, {
    method: 'DELETE',
  });
};

export const getExportFinance = () =>
  fetch(`${FINANCE_TABS_URL}/Export`, {
    method: 'GET',
  });
