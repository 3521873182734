import React from 'react';
import Modal from 'react-modal';

import useAppState, { useAppDispatch } from '~/appState';
import { showErrorModal } from '~/actions/global';
import { ReactComponent as Bell } from '~/assets/svg/Bell.svg';
import './styles.scss';

const ErrorModal = () => {
  const {
    global: { isShowedError, errorMessage },
  } = useAppState();
  const dispatch = useAppDispatch();

  const handleCloseClick = () => {
    dispatch(showErrorModal());
  };

  const renderMessage = (message) => {
    if (Array.isArray(message)) {
      return message.slice(0, 2).map((msg) => <span key={msg}>{msg}</span>);
    } else if (typeof message === 'string') {
      return message;
    } else {
      return 'Oops! Something went wrong.';
    }
  };

  return (
    <Modal
      className="modal-error"
      isOpen={isShowedError}
      onRequestClose={handleCloseClick}
      shouldCloseOnOverlayClick={true}
    >
      <button className="close-modal" onClick={handleCloseClick}>
        <span>&times;</span>
      </button>
      <div className="error_resource-modal-row">
        <span>
          <Bell />
        </span>
        <div className="error_resource-modal-description">
          {renderMessage(errorMessage)}
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
